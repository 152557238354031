import React, { useEffect } from 'react';
import type { Tracker } from '@yoursurprise/segment-analytics';
import { useSearchBox } from 'react-instantsearch-core';
import pubSubJS from 'pubsub-js';
import Results from '../Results/Results';
import { FilterContainer } from '../FilterList/FilterContainer';
import { SEARCH_STATE_CHANGE } from '../event/eventTypes';
import useFixAnalytics from '../Analytics/useFixAnalytics';

interface SearchPageProps {
    collapsed?: boolean;
    currency: string;
    distinct?: boolean;
    editableButton?: React.ReactElement;
    hasFilter?: boolean;
    hidePagination?: boolean;
    imageOverrides?: Record<number, string>;
    limit?: number;
    photoGiftUrl: string;
    productIndexName: string;
    replicaIndexBase: string;
    tracker: Tracker | undefined;
}

const SearchPage = React.forwardRef<HTMLDivElement, SearchPageProps>(({
    collapsed,
    currency,
    distinct,
    editableButton,
    hasFilter = true,
    hidePagination,
    imageOverrides = {},
    limit,
    photoGiftUrl,
    productIndexName,
    replicaIndexBase,
    tracker,
}, ref) => {
    const { refine } = useSearchBox();

    useFixAnalytics();

    useEffect(() => {
        pubSubJS.subscribe(SEARCH_STATE_CHANGE, (_: unknown, value: string) => {
            refine(value);
        });

        return () => {
            pubSubJS.unsubscribe(SEARCH_STATE_CHANGE);
        };
    }, [refine]);

    return (
        <>
            { hasFilter && <FilterContainer
                productIndexName={productIndexName}
                replicaIndexBase={replicaIndexBase}
                currency={currency}
                limit={limit}
            /> }
            <Results
                collapsed={collapsed}
                currency={currency}
                distinct={distinct}
                editableButton={editableButton}
                hidePagination={hidePagination}
                tracker={tracker}
                photoGiftUrl={photoGiftUrl}
                forwardRef={ref}
                imageOverrides={imageOverrides}/>
        </>
    );
});

export default SearchPage;
