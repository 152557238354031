import type React from 'react';
import { useContext, useState } from 'react';
import { useInstantSearch, useSortBy } from 'react-instantsearch-core';
import { useDynamicWidgets } from 'react-instantsearch';
import OverlayFilterList from './OverlayFilterList';
import type { SortByItem } from '../SortBy/SortBy';
import ActiveFilterContainer from './ActiveFilter/ActiveFilterContainer';
import FilterNavigation from './FilterNavigation';
import Section from '../../../general/Section/Section';
import algoliaResultsToEav from '../algoliaResultsToEav';
import Stats from '../Stats/Stats';
import useTranslate from '../../../general/Translation/hooks/UseTranslate';
import { WebshopContext } from '../../../general/WebshopContext/WebshopContextProvider';
import activeFlags from '../../../../js/optimizely/fx/flags';

export interface FilterContainerProps {
    currency: string;
    limit?: number;
    productIndexName: string;
    replicaIndexBase: string;
}

export const FilterContainer: React.FC<FilterContainerProps> = ({
    currency,
    limit,
    productIndexName,
    replicaIndexBase,
}) => {
    // This makes sure that the filters are populated with values
    // If removed, all filters will be empty until a client side filter takes place
    // tl;dr: Don't remove this.
    useDynamicWidgets({ facets: ['*'] });

    const translate = useTranslate();
    const { indexUiState, results: searchResults } = useInstantSearch();
    const [visibility, setVisibility] = useState<boolean[]>(Array.from(Array(5)).map(() => false));

    const sortOp = translate('overview_page', 'order_by');
    const price = translate('overview_page', 'price');
    const isVariationB = (useContext(WebshopContext).experimentVariation === activeFlags.plpHidePriceFilter.variation);

    const sortByItems: SortByItem[] = [
        { label: translate('overview_page', 'score_desc'), value: productIndexName },
        { label: translate('overview_page', 'price_asc'), value: `${replicaIndexBase}-price_asc` },
        { label: translate('overview_page', 'price_desc'), value: `${replicaIndexBase}-price_desc` },
    ];

    const sortByState = useSortBy({ items: sortByItems });

    const currentSortedItem = sortByItems.find((item) => item.value === sortByState.currentRefinement);

    const { eavs: sortedResults, filtersWithRules } = algoliaResultsToEav(searchResults, indexUiState);
    const popularFilters = sortedResults.slice(0, 3);

    const navigationItems = [...(!isVariationB ? [{ key: 'price', label: price }] : []), ...popularFilters.map((filter) => ({
        key: filter.attributeName,
        label: filter.label,
    }))];

    const toggleFilter = (key: number): void => {
        const newVisibility = [...visibility];
        newVisibility[key] = !newVisibility[key];
        setVisibility(newVisibility);
    };

    return (
        <Section isSm>
            {Boolean(searchResults.hits.length) && <div className="product-filter-wrapper">
                <div className="product-filter-group">
                    {navigationItems.map((item, i) => (
                        <FilterNavigation key={item.key} item={item.label} openFilter={() => {
                            toggleFilter(i + 1);
                        }}/>
                    ))}</div>
                <div className="product-filter-group product-filter-group--no-scroll">
                    <Stats limit={limit}/>
                    <FilterNavigation isSorting
                        item={`${sortOp} ${currentSortedItem ? currentSortedItem.label : ''}`}
                        openFilter={() => toggleFilter(0)}/>
                </div>
            </div> }
            <OverlayFilterList
                filtersWithRules={filtersWithRules}
                currency={currency}
                visibility={visibility}
                items={sortByItems}
                setVisibility={setVisibility}
                popularFilters={popularFilters}
            />
            <ActiveFilterContainer currency={currency}/>
        </Section>
    );
};
