import type React from 'react';
import { useRange } from 'react-instantsearch-core';
import ActiveFilter from './ActiveFilter';
import FormatPrice from '../../../../general/Format/FormatPrice';

interface ActivePriceFilterProps {
    attribute: string;
    currency: string;
}

const ActivePriceFilter: React.FC<ActivePriceFilterProps> = ({ attribute, currency }) => {
    const { canRefine, range, refine, start } = useRange({ attribute });

    if (!canRefine) {
        return null;
    }

    // The start values will begin as [-Infinity, Infinity] until the price filter has been applied.
    // Don't be fooled by the 'start' name, start contains the min and max values _after_ filtering.
    const [filteredRangeMin, filteredRangeMax] = start;

    const isRefined = Number.isFinite(filteredRangeMin) || Number.isFinite(filteredRangeMax);
    const minRangeValue = Number.isFinite(filteredRangeMin) ? filteredRangeMin : range.min;
    const maxRangeValue = Number.isFinite(filteredRangeMax) ? filteredRangeMax : range.max;

    // We don't show the price filter if there is no change made or if we don't have a range to show
    if (!isRefined || typeof minRangeValue === 'undefined' || typeof maxRangeValue === 'undefined') {
        return null;
    }

    const activePriceLabel = <><FormatPrice price={{ currency, value: minRangeValue }} /> - <FormatPrice price={{ currency, value: maxRangeValue }} /></>;

    return <ActiveFilter label={activePriceLabel} onRemoveFilter={() => refine([range.min, range.max])} />;
};

export default ActivePriceFilter;
