import type React from 'react';
import { useEffect, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { useRange } from 'react-instantsearch-core';
import FormatPrice from '../../../../general/Format/FormatPrice';
import FilterHeader from '../Filter/FilterHeader';
import ActiveFilter from '../ActiveFilter/ActiveFilter';

interface RangeProps {
    attribute: string;
    clicked: boolean;
    currency: string;
    isOpen: boolean;
    label: string;
}

export const RangeSlider: React.FC<RangeProps> = ({
    attribute,
    clicked,
    currency,
    isOpen,
    label,
}) => {
    const { canRefine, range, refine, start } = useRange({ attribute });
    const [filteredRangeMin = 0, filteredRangeMax = 0] = start;
    const { max = 0, min = 0 } = range;
    const [rangeValue, setRangeValue] = useState({ end: max, start: min });
    const [showItems, setShowItems] = useState<boolean>(isOpen);

    const from = Math.max(min, Number.isFinite(filteredRangeMin) ? filteredRangeMin : min);
    const to = Math.min(max, Number.isFinite(filteredRangeMax) ? filteredRangeMax : max);

    useEffect(() => {
        // this sets the value to max and min when filters are used
        setRangeValue({ end: to, start: from });
    }, [from, to]);

    useEffect(() => (setShowItems(isOpen)), [isOpen, clicked]);

    if ((min === 0 && max === 0) || !canRefine) {
        return null;
    }

    const isValueChanged = Number.isFinite(filteredRangeMin) || Number.isFinite(filteredRangeMax);

    const changeValue = (values: number[]) => {
        if (typeof values[0] === 'undefined' || typeof values[1] === 'undefined') {
            return;
        }
        setRangeValue({ end: values[1], start: values[0] });
    };

    const asPrice = (value: number) => ({ currency, value });

    return (
        <div className="filter">
            <FilterHeader
                toggleItems={() => setShowItems(!showItems)}
                showItems={showItems}
                label={label}
            />
            {!showItems && isValueChanged && (
                <div className="filter-active">
                    <ActiveFilter label={`${rangeValue.start}-${rangeValue.end} ${currency}`} onRemoveFilter={() => refine([min, max])}/>
                </div>
            )}
            {showItems && (
                <div className="filter-list" data-testid="filter-list">
                    <div className="filter-price-wrapper">
                        <Range
                            values={[rangeValue.start, rangeValue.end]}
                            step={1}
                            min={min}
                            max={max}
                            onChange={(values) => changeValue(values)}
                            onFinalChange={(values) => refine([values[0], values[1]])}
                            renderTrack={({ children, props }) => (
                                <div className="filter-price-progress-bar-wrapper"
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}>
                                    <div
                                        className="filter-price-progress-bar"
                                        ref={props.ref}
                                        style={{
                                            background: getTrackBackground({
                                                colors: ['#F4EFEB', 'var(--color-pink)', '#F4EFEB'],
                                                max,
                                                min,
                                                values: [rangeValue.start, rangeValue.end],
                                            }),
                                        }}
                                    >
                                        {children}
                                    </div>
                                </div>
                            )}
                            renderThumb={({ props }) => (
                                // eslint-disable-next-line
                                        <div className="filter-price-handle" {...props} />
                            )}
                        />
                        <div className="filter-price-value-container">
                            <div><FormatPrice price={asPrice(rangeValue.start)}/></div>
                            <div><FormatPrice price={asPrice(rangeValue.end)}/></div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default RangeSlider;
