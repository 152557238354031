import type React from 'react';
import { useState } from 'react';
import FilterList from './FilterList';
import type { EavData } from '../Input';
import type { SortByItem } from '../SortBy/SortBy';
import Backdrop from '../../../../stories/Atoms/Backdrop/Backdrop';

interface OverlayFilterListProps {
    currency: string;
    filtersWithRules: string[];
    items: SortByItem[];
    popularFilters: EavData[];
    setVisibility: (value: boolean[]) => void;
    visibility: boolean[];
}

const OverlayFilterList: React.FC<OverlayFilterListProps> = (
    {
        currency,
        filtersWithRules,
        items,
        popularFilters,
        setVisibility,
        visibility,

    },
) => {
    const isVisible = visibility.some((item) => item);
    const [clicked, setClicked] = useState<boolean>(false);

    const closeFilter = () => {
        setVisibility(Array.from(Array(5)).map(() => false));
        setClicked(!clicked);
    };

    return (
        <Backdrop onClose={closeFilter} isOpen={isVisible}>
            <FilterList
                filtersWithRules={filtersWithRules}
                currency={currency}
                items={items}
                onFilterClose={closeFilter}
                popularFilters={popularFilters}
                visibility={visibility}
                clicked={clicked}
            />
        </Backdrop>
    );
};

export default OverlayFilterList;
