import type { FormEventHandler, ReactNode } from 'react';
import React from 'react';
import classnames from 'classnames';
import styles from './Button.module.scss';
import type { IconName } from '../Icon/Icon';
import Icon from '../Icon/Icon';

interface ButtonProps {
    children: ReactNode;
    className?: string;
    clean?: boolean;
    disabled?: boolean;
    fluid?: boolean;
    form?: string;
    icon?: IconName;
    label?: string;
    loading?: boolean;
    onClick?: FormEventHandler;
    primary?: boolean;
    rounded?: boolean;
    secondary?: boolean;
    size?: 'small' | 'medium' | 'large';
    square?: boolean;
    type?: 'button' | 'submit';
}

const Button: React.FC<ButtonProps> = (
    {
        children,
        className,
        clean = false,
        disabled = false,
        fluid,
        icon: iconName,
        label,
        loading = false,
        primary = false,
        rounded = false,
        secondary = false,
        size = 'medium',
        square = false,
        type = 'submit',
        ...props
    },
) => {
    const icon = loading
        ? <Icon name="fa-spinner-third" style="fa-duotone" animation="fa-spin"/>
        : (iconName && <Icon name={iconName}/>);

    return (
        <button
            aria-label={label}
            type={type}
            disabled={disabled}
            className={classnames(styles.Button, {
                [styles.Button_primary as string]: primary,
                [styles.Button_secondary as string]: secondary,
                [styles.Button_square as string]: square,
                [styles.Button_small as string]: size === 'small',
                [styles.Button_medium as string]: size === 'medium',
                [styles.Button_large as string]: size === 'large',
                [styles.Button_rounded as string]: rounded,
                [styles.Button_clean as string]: clean,
                [styles.Button_fluid as string]: fluid,
            }, className)}
            aria-busy={loading}
            {...props}
        >
            <span className={classnames({ [styles.Button__loader as string]: loading })}>
                {icon && (
                    <span
                        className={classnames(
                            styles.Button__icon,
                            { [styles.Button__loader__icon as string]: !iconName },
                        )}
                    >
                        {icon}
                    </span>
                )}
                <span
                    aria-hidden={!iconName && loading}
                    className={classnames({
                        [styles.Button__hidden as string]: !iconName && loading,
                    })}
                >
                    {children}
                </span>
            </span>
        </button>
    );
};

export default Button;
