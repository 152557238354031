type Flags = {
    flagKey: string;
    variation: string;
};

const plpHidePriceFilter: Flags = {
    flagKey: 'b4-plp-hide-price-filter',
    variation: 'hide_price_filter',
};

type ActiveFlags = {
    plpHidePriceFilter: Flags;
};

const activeFlags: ActiveFlags = {
    plpHidePriceFilter,
};

export default activeFlags;
